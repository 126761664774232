#aboutUs {
  background: #41826c;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  .aboutUs-title {
    height: 66px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-bottom: 34px;
  }
  .aboutUs-text {
    width: 629px;
    height: 223px;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
  }
}

@media (max-width: 600px) {
  #aboutUs {
    width: auto;
    height: 390px;
    .aboutUs-title {
      width: auto;
      height: 35px;
      font-weight: 900;
      font-size: 18px;
      line-height: 143%;
      border-bottom: 16px;
    }
    .aboutUs-text {
      width: 297px;
      height: 318px;
      font-weight: 400;
      font-size: 18px;
    }
  }
}
