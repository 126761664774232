#coverDescription {
  background: linear-gradient(180deg, #2f5e4e 0%, #1d3b31 100%);
  height: 166px;
  font-family: "Roboto";
  font-style: normal;
  color: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h1 {
    width: 996px;
    height: 41px;
    left: 226px;
    top: 9px;
    font-weight: 700;
    font-size: 36px;
    line-height: 114%;
    text-align: center;
  }
  h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
  }
}
@media (max-width: 600px) {
  #coverDescription {
    height: 173px;
    h1 {
      width: 326px;
      height: 54px;
      left: 0px;
      top: 0px;
      font-size: 24px;
    }
    h2 {
      width: 326px;
      height: 48px;
      left: 0px;
      top: 62px;
      font-size: 16px;
      line-height: 150%;
    }
  }
}

@media only screen and (min-device-width : 601px) and (max-device-width : 1024px) {
  #coverDescription{
    h1{
      width: auto;
    }
  }
}