#services{ 
    padding: 8px 0;
    background-color: #E5E5E5;
    h2{
        text-align: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        width: auto;
        color: #41826C;
        text-transform: uppercase;
        font-size: 24px;
        margin-bottom: 20px;
        span{
            color: #1D3B31;
        }
    }
}

// MEDIA QUERIES //
/* iPhone 4 ----------- */
@media only screen and (min-device-width : 100px) and (max-device-width : 319px) {
    #services{
        .col{
            display: flex;
            justify-content: center;
        }
        .row{
            margin-left: 0;
            margin-right: 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .serviceCard{
        width: 90% !important;
        height: 30% !important;
        .cardContent{
            justify-content: flex-start;
        }
    }
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    #services{
        .col{
            display: flex;
            justify-content: center;
        }
        .row{
            margin-left: 0;
            margin-right: 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .serviceCard{
        width: 90% !important;
        .cardContent{
            justify-content: flex-start;
        }
    }
}

@media only screen and (min-device-width : 481px) and (max-device-width : 767px) {
    #services{
        .container{
            display: flex;
            flex-direction: row;
        }
        .col{
            display: flex;
            justify-content: center;
        }
        .row{
            margin-left: 0;
            margin-right: 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .serviceCard{
        width: 95% !important;
        height: 280px !important;

        .cardContent{
            img{
                width: 0px;
            }
        }
    }
}

// @media only screen and (min-device-width : 481px) and (max-device-width : 767px){
//     #services{
//         .serviceCard{
//             width: 95% !important;
//             height: 280px;
//         }
//     }
// }

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    #services{
        .container{
            display: flex;
            flex-direction: row;
        }
        .col{
            display: flex;
            justify-content: center;
        }
        .row{
            margin-left: 0;
            margin-right: 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .serviceCard{
        width: 95% !important;
        height: 210px;
    }
}
/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    #services{
        .serviceCard{
            height: 220px !important;
        }
    }
}

@media only screen and (min-device-width : 1025px) and (max-device-width : 1223px) {
    #services{
        .container{
            display: flex;
            flex-direction: row;
        }
        .col{
            display: flex;
            justify-content: center;
        }
        .row{
            margin-left: 0;
            margin-right: 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .serviceCard{
        width: 95% !important;
        height: 210px;
    }
}


/* Desktops and laptops ----------- */
@media only screen and (min-width : 1224px) {
  /* Styles */
}
/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
  /* Styles */
}