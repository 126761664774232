#Header {
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
    height: auto;
  }

  .header-wpbutton {
    height: 40px;
    width: 120px;
    left: 0px;
    margin-right: 30px;
    top: 0px;
    border-radius: 60px;
    padding: 12px, 22px, 12px, 22px;
    background: #34c759;
    border: #34c759;
    &:hover {
      animation: wpbutton-hover;
      animation-duration: .5s;
      animation-fill-mode: forwards;
    }

    @keyframes wpbutton-hover {
      0% {
        background-color: #34c759;
      }
      100% {
        background-color: #41826c;
      }
    }
  }
  .header-callbutton {
    height: 40px;
    width: 120px;
    left: 0px;
    top: 0px;
    margin-right: 50px;
    border-radius: 60px;
    padding: 12px, 22px, 12px, 22px;
    background: #8b00a8;
    border: #8b00a8;
    &:hover {
      animation: callbutton-hover;
      animation-duration: .5s;
      animation-fill-mode: forwards;
    }

    @keyframes callbutton-hover {
      0% {
        background-color: #8b00a8;
      }
      100% {
        background-color: #68127a;
      }
    }
  }

  #mobile-display-none{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

@media (max-width: 600px) {
  .navbar-header {
    padding: 20px 16px 16px;
    height: 61px;
    left: 0px;
    background: #ffffff;
    display: flex;
    justify-content: center !important;
    .header-img {
      display: flex;
      justify-content: center !important;
    }
    img {
      position: static;
      width: 227px;
      height: 39.14px;
      left: 56px;
      top: 5.93px;
    } 
  }
  #header-logo{
    width: 95% !important;
  }

  #mobile-display-none {
    display: none !important;
  }
}
