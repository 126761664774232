#formSection{
    background: linear-gradient(180deg, #41826C 0%, #1D3B31 100%);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 24px 0;

    small{
        font-family: 'Roboto';
        font-style: normal;
        color: lighten(#000000, 70%)
    }

    #form-alert{
        padding: 8px;
        text-align: center;
        p{
            padding: 0;
            margin: 0;
        }
    }

    .invalid{
        border: solid 2px !important;
        border-color: darken(red, 5%) !important;
        border-radius: 10px !important;
    }

    .valid{
        border: solid 2px !important;
        border-color: darken(green, 5%) !important;
        border-radius: 10px !important;
    }

    @mixin inputs {
        caret-color: #41826C;
        border-color: transparent;
        width: 80%;
        &::placeholder{
            font-family: 'Roboto';
            color: rgba(60, 60, 67, 0.6);
        }
    }
    textarea{
        @include inputs;
    }

    h2{
        text-transform: uppercase;
        text-align: center;
        color: #FFFF;
        font-size: 24px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        margin-bottom: 16px;
        span{
            color: #E8BD4F;
        }
    }
    img{
        width: 480px
    }
    form{
        background: #FFFFFF;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 330px;
        padding: 8px;
        .form-title{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;
            color: #41826C;
            
        }
        hr{
            width: 80%;
            margin: 8px 0;
        }
        input{
            @include inputs;
        }
        .btn-submit-form{
            color: white;
            padding: 13px 20px;
            border-radius: 10px;
            margin: 5px 0;
            width: 160px;
            background-color: #E8BD4F;

            &:hover{
                animation: submit-hover;
                animation-duration: .5s;
                animation-fill-mode: forwards;
            }

            @keyframes submit-hover{
                0% {
                background-color: #E8BD4F;
                }
                100% {
                background-color: #C8A039;
                }
            }
        }
    }



}

// MEDIA QUERIES //
/* iPhone 4 ----------- */
@media only screen and (min-device-width : 100px) and (max-device-width : 319px) {
    #formSection{
        display: flex;
        flex-direction: column !important;
        justify-content: space-evenly;
        align-items: center;

        img{
            margin-bottom: 16px;
            width: 90%;
        }
        form{
            width: 90%;
        }
    }
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    #formSection{
        display: flex;
        flex-direction: column !important;
        justify-content: space-evenly;
        align-items: center;

        .form-title-img{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        img{
            margin-bottom: 16px;
            width: 90%;
        }
        form{
            width: 90%;
        }
    }
}

@media only screen and (min-device-width : 481px) and (max-device-width : 767px) {
    #formSection{
        display: flex;
        flex-direction: column !important;
        justify-content: space-evenly;
        align-items: center;

        .form-title-img{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        img{
            margin-bottom: 16px;
            width: 90%;
        }
        form{
            width: 90%;
        }
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    #formSection{
        img{
            width: 80%;
        }
        form{
            width: 30%;
        }
        #btn-submit-form{
            padding: 8px 15px;
        }
        hr{
            margin: 4px 0 !important;
        }
    }
}
// /* iPads (landscape) ----------- */
// @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
//   /* Styles */
// }
// /* iPads (portrait) ----------- */
// @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
//   /* Styles */
// }
// /* Desktops and laptops ----------- */
// @media only screen and (min-width : 1224px) {
//   /* Styles */
// }
// /* Large screens ----------- */
// @media only screen and (min-width : 1824px) {
//   /* Styles */
// }
